/**
 * Constants
 */

/* Examples - Dummy data */
export const TRANSLATE_TEXT = 'This is a dummy text.';

/* Environments */
export const DEVELOPMENT = 'https://backend.easier-integration.nuromedia.com/';
export const DEVELOPMENT_TWO =
  'https://backend.easier-integration.nuromedia.com';
// export const TEST = 'https://backend.easier-test.nuromedia.com/';
// export const TEST_TWO = 'https://backend.easier-test.nuromedia.com';
export const TEST = 'https://backend.easier-integration.nuromedia.com/';
export const TEST_TWO = 'https://backend.easier-integration.nuromedia.com';
export const EXPERIMENTAL =
  'https://backend.experimental.easier-integration.nuromedia.com/';
export const EXPERIMENTAL_TWO =
  'https://backend.experimental.easier-integration.nuromedia.com';

/* Categories */
export const SPEECH = 'speech';
export const AUDIO = 'audio';
export const TEXT = 'text';
export const VIDEO = 'video';
export const AVATAR = 'avatar';
export const CAMERA = 'camera';

/* Archive */
export const ALL_ARCHIVE = 'all';

/* Profile */
export const USERNAME = 'User';
export const USERID = 'USER1234';

/* Test account */
export const EMAIL_USER = 'user@example.com';
export const PASSWORD = 'user';

/* Token / Storage */
export const USERTOKEN_KEY = '@userAuth';
export const USERREFRESHTOKEN_KEY = '@userRefreshAuth';
export const TOKEN_TIMER = '@tokenTimer';
export const USERTOKEN_VALUE = 'User';
export const DEFAULT_VALUE_TYPE = '@defaultValueType';
export const DEFAULT_OUTPUT_LANGUAGE_TYPE = '@defaultOutputLanguageType';
export const DEFAULT_OUTPUT_TYPE = '@defaultOutputType';
export const DEFAULT_INPUT_LANGUAGE_TYPE = '@defaultInputLanguageType';
export const DEFAULT_INPUT_TYPE = '@defaultInputType';
export const SELECT_INPUT_TYPE = '@selectInputType';
export const SELECT_INPUT_LANGUAGE_TYPE = '@selectInputLanguageType';
export const SELECT_OUTPUT_TYPE = '@selectOutputType';
export const SELECT_OUTPUT_LANGUAGE_TYPE = '@selectOutputLanguageType';
export const RESET_EMAIL = '@resetEmail';
export const AVATAR_CLOTHES_TYPE = '@avatarClothesType';
export const AVATAR_GENDER_TYPE = '@avatarGenderType';
export const AVATAR_BACKGROUND_TYPE = '@avatarBackgroundType';
export const AVATAR_SKIN_TYPE = '@avatarSkinType';
export const AVATAR_CONTRAST_TYPE = '@avatarContrastType';
export const VOICE_ANNOTATION_TYPE = '@voiceIntonationType';
export const VOICE_CLOTHES_TYPE = '@voiceClothesType';
export const VOICE_GENDER_TYPE = '@voiceGenderType';
export const VOICE_LANGUAGE_TYPE = '@voiceLanguageType';
export const CONTRAST_SIZE_TYPE = '@contrastSizeType';
export const CONTRAST_MODE_TYPE = '@contrastModeType';
export const TEXT_VALUE = '@textInput';
export const AUDIO_RECORDING = '@speechInput';
export const VIDEO_RECORDING = '@cameraInput';
export const SETTINGS_CHOICE = '@settingsChoice';
export const AVATAR_CHOICE = '@avatarChoice';
export const AVATAR_BACKGROUND = '@avatarBackground';
export const AVATAR_SHIRT = '@avatarShirt';
export const DEFAULT_OPTION_TYPE = '@defaultOptionType';

/* Settings - Contrast */
export const SMALLTEXT = 'small';
export const NORMALTEXT = 'normal';
export const BIGTEXT = 'big';

/* Settings - Avatar */
export const AVATAR_BACKGROUND_BLUE = 'blue';
export const AVATAR_BACKGROUND_GREY_LIGHT = 'grey-light';
export const AVATAR_BACKGROUND_WHITE = 'white';
export const AVATAR_CLOTHES_GREEN = 'green';
export const AVATAR_CLOTHES_PURPLE = 'purple';
export const AVATAR_CLOTHES_GREY = 'grey';
export const AVATAR_GENDER_MALE = 'male';
export const AVATAR_GENDER_FEMALE = 'female';
export const AVATAR_GENDER_DIVERS = 'divers';
export const AVATAR_SKIN_LIGHT = 'light';
export const AVATAR_SKIN_LIGHT_DARK = 'light-dark';
export const AVATAR_SKIN_NORMAL = 'normal';
export const AVATAR_SKIN_DARK_LIGHT = 'dark-light';
export const AVATAR_SKIN_DARK = 'dark';
export const AVATAR_CONTRAST_RED = 'red';
export const AVATAR_CONTRAST_GREEN = 'green';
export const AVATAR_CONTRAST_BLUE = 'blue';

/* Settings - Choice */
export const SETTINGS_NORMAL = 'normal';
export const SETTINGS_FAVORITE = 'favorite';
export const AVATAR_NORMAL = 'normal';
export const AVATAR_FAVORITE = 'favorite';

/* Settings - Voice */
export const VOICE_GENDER_MALE = 'male';
export const VOICE_GENDER_FEMALE = 'female';
export const VOICE_GENDER_DIVERS = 'divers';
export const VOICE_CLOTHES_BUSINESS = 'business';
export const VOICE_CLOTHES_CASUAL = 'casual';
export const VOICE_LANGUAGE_BRITISH = 'british';
export const VOICE_LANGUAGE_AMERICAN = 'american';

/* Settings - Languages */
export const LANGUAGE_FRENCH = 'french';
export const LANGUAGE_GERMAN = 'german';
export const LANGUAGE_GREEK = 'greek';
export const LANGUAGE_ITALIAN = 'italian';
export const LANGUAGE_LUXEMBOURG_FRENCH = 'luxembourg-french';
export const LANGUAGE_LUXEMBOURG_GERMAN = 'luxembourg-german';
export const LANGUAGE_DUTCH = 'dutch';
export const LANGUAGE_SUIZZE_FRENCH = 'suizze-french';
export const LANGUAGE_SUIZZE_ITALIAN = 'suizze-italian';
export const LANGUAGE_SUIZZE_GERMAN = 'suizze-german';
export const LANGUAGE_BRITISH = 'british';
export const APP_LANGUAGE = 'APP_LANGUAGE';

/* Limits */
export const NEW_PASSWORD_LENGTH = 5;

/* Info text */
export const MAIN_TEXT =
  'EASIER is a Horizon 2020 project that aims to design, develop, and validate a complete multilingual machine translation system that will act as a framework for barrier-free communication among deaf and hearing individuals, as well as provide a platform to support sign language content creation.';
export const SUB_TEXT =
  'The project foresees a strong involvement of end-users in design, development, and evaluation of the EASIER ecosystem, where users of various European sign and spoken languages will be able to use their preferred language to interact with each other.';
export const FOUNDED_TEXT =
  'EASIER project has been funded by the EU’s Horizon 2020 programme under Grant Agreement number 101016982.';
export const LINK_TEXT = 'www.project-easier.eu';
export const LINK_WEBSITE = 'https://www.project-easier.eu';
